.company-brown {
    background-color: #97613f;
    color: #97613f;
}

.company-velvet {
    background-color: #820b35;
}

.tabs-company-velvet {
    background-color: #820b35;
}

.company-primary {
    background-color: #f2930d;
}